<template>
  <section id="partners-carousel">
    <div class="container overflow-hidden" v-bind:class="background">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pb-4">
          <p class="h2 text-main fw-bolder text-center">
            Əməkdaşlıq etdiyimiz sığortaçılar
          </p>
        </div>
      </div>
    </div>
    <div
      class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pb-4 carousel-partners-items"
    >
      <swiper
        :spaceBetween="30"
        :centeredSlides="true"
        :autoplay="{
          delay: 0,
          disableOnInteraction: true,
        }"
        :loop="true"
        :slidesPerView="6"
        :breakpoints="breakpoints"
        :speed="8000"
        :mousewheelControl="true"
        :keyboardControl="true"
        :modules="modules"
        @autoplayTimeLeft="onAutoplayTimeLeft"
        class="mySwiper"
      >
        <swiper-slide>
          <div class="carousel-partners-item">
            <img
              class="d-block mx-auto carousel-img-fluid"
              src="@/assets/images/pasha.svg"
              alt="Second slide"
            />
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="carousel-partners-item">
            <img
              class="d-block mx-auto carousel-img-fluid"
              src="@/assets/images/senayeSigorta.svg"
              alt="Second slide"
            />
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="carousel-partners-item">
            <img
              class="d-block mx-auto carousel-img-fluid"
              src="@/assets/images/qala.svg"
              alt="Second slide"
            />
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="carousel-partners-item">
            <img
              class="d-block mx-auto carousel-img-fluid"
              src="@/assets/images/xalq.svg"
              alt="Second slide"
            />
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="carousel-partners-item">
            <img
              class="d-block mx-auto carousel-img-fluid"
              src="@/assets/images/mega.svg"
              alt="First slide"
            />
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="carousel-partners-item">
            <img
              class="d-block mx-auto carousel-img-fluid"
              src="@/assets/images/ateshgahSigorta.svg"
              alt="First slide"
            />
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="carousel-partners-item">
            <img
              class="d-block mx-auto carousel-img-fluid"
              src="@/assets/images/ataSigorta.svg"
              alt="First slide"
            />
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="carousel-partners-item">
            <img
              class="d-block mx-auto carousel-img-fluid"
              src="@/assets/images/aGroup.svg"
              alt="First slide"
            />
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </section>
</template>

<script>
// Import Swiper Vue.js components
import { Swiper } from 'swiper/vue/swiper'
import { SwiperSlide } from 'swiper/vue/swiper-slide'
import SwiperCore, { Autoplay } from 'swiper'
// Import Swiper styles
import 'swiper/swiper.min.css'
import 'swiper/swiper-bundle.css'
import 'swiper/modules/pagination/pagination.min.css'
SwiperCore.use([Autoplay])
export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      effect: 'cube',
      grabCursor: true,
      loop: true,
      centeredSlides: true,
      slidesPerView: 4,
      autoplay: {
        delay: 0,
        disableOnInteraction: false,
      },
      breakpoints: {
        300: {
          slidesPerView: 2,
          spaceBetween: 30,
        },
        576: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
        768: {
          slidesPerView: 4,
          spaceBetween: 40,
        },
      },
    }
  },
}
</script>
